export const states = [
    { value: "Andhra Pradesh" },
    { value: "Arunachal Pradesh" },
    { value: "Assam" },
    { value: "Bihar" },
    { value: "Chandigarh" },
    { value: "Chhattisgarh" },
    { value: "Delhi" },
    { value: "Goa" },
    { value: "Gujarat" },
    { value: "Haryana" },
    { value: "Himachal Pradesh" },
    { value: "Jammu and Kashmir" },
    { value: "Jharkhand" },
    { value: "Karnataka" },
    { value: "Kerala" },
    { value: "Ladakh" },
    { value: "Madhya Pradesh" },
    { value: "Maharashtra" },
    { value: "Manipur" },
    { value: "Meghalaya" },
    { value: "Mizoram" },
    { value: "Nagaland" },
    { value: "Odisha" },
    { value: "Puducherry" },
    { value: "Punjab" },
    { value: "Rajasthan" },
    { value: "Sikkim" },
    { value: "Tamil Nadu" },
    { value: "Telangana" },
    { value: "Tripura" },
    { value: "Uttarakhand" },
    { value: "Uttar Pradesh" },
    { value: "West Bengal" },
  ];