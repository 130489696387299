// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'orders', path: PATH_DASHBOARD.general.orders, icon: ICONS.ecommerce },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER

      // MANAGEMENT : E-COMMERCE
      {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'product list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'add product', path: PATH_DASHBOARD.eCommerce.newProduct },
         ],
      },
      {
        title: 'banner',
        path: PATH_DASHBOARD.banner.root,
        icon: ICONS.kanban,
        children: [
          { title: 'new banner', path: PATH_DASHBOARD.banner.newBanner },
        ],
      },
      {
        title: 'portfolio',
        path: PATH_DASHBOARD.portfolio.root,
        icon: ICONS.kanban,
        children: [
          { title: 'new portfolio', path: PATH_DASHBOARD.portfolio.newPortfolio },
          { title: 'portfolio list', path: PATH_DASHBOARD.portfolio.list },
        ],
      },
      {
        title: 'texture',
        path: PATH_DASHBOARD.texture.root,
        icon: ICONS.booking,
        children: [
          { title: 'new texture', path: PATH_DASHBOARD.texture.new },
        ],
      },
      {
        title: 'generate invoice',
        path: PATH_DASHBOARD.invoice.create,
        icon: ICONS.invoice,
      },
      {
        title: 'policy',
        path: PATH_DASHBOARD.policy.root,
        icon: ICONS.banking,
        children: [
          { title: 'return policy', path: PATH_DASHBOARD.policy.returnPolicy },
          { title: 'shipping policy', path: PATH_DASHBOARD.policy.shippingPolicy },
          { title: 'privacy policy', path: PATH_DASHBOARD.policy.privacyPolicy },
          { title: 'terms and conditions', path: PATH_DASHBOARD.policy.tac },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  
];

export default navConfig;
