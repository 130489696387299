import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { List, ListItemText, ListItem } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
//
import Iconify from '../Iconify';
import { varFade } from '../animate';

// ----------------------------------------------------------------------

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
  };
};

// ----------------------------------------------------------------------

FilePreview.propTypes = {
  file: PropTypes.object,
  showPreview: PropTypes.bool,
};

export default function FilePreview({ file }) {
  const { key, name, size } = getFileData(file);

  return (
      <List disablePadding sx={{ ...(file && { my: 3 }) }}>
        <AnimatePresence>
              <ListItem
                key={key}
                component={m.div}
                {...varFade().inRight}
                sx={{
                  my: 1,
                  px: 2,
                  py: 0.75,
                  borderRadius: 0.75,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

                <ListItemText
                  primary={isString(file) ? file : name}
                  secondary={isString(file) ? '' : fData(size || 0)}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
              </ListItem>
        </AnimatePresence>
      </List>
  );
}
