import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'orders', element: <GeneralOrders /> },
        { path: 'order', element: <GeneralOrder /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/list" replace />, index: true },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
           ],
        },
        {
          path: 'models',
          children: [
            { element: <Navigate to="/dashboard/models/list" replace />, index: true },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'model', element: <EcommerceModel /> },
           ],
        },
        {
          path: 'texture',
          children: [
            { element: <Navigate to="/dashboard/texture/list" replace />, index: true },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'new', element: <EcommerceTexture /> },
           ],
        },
        {
          path: 'banner',
          children: [
            { element: <Navigate to="/dashboard/banner/view-banner" replace />, index: true },
           { path: 'new-banner', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'portfolio',
          children: [
            { element: <Navigate to="/dashboard/portfolio" replace />, index: true },
           { path: 'new-portfolio', element: <NewPortfolio /> },
           { path: 'list', element: <PortfolioList /> },
           { path: 'edit', element: <EditPortfolio /> },
          ],
        },
        {
          path: 'policy',
          children: [
            { element: <Navigate to="/dashboard/policy/return-policy" replace />, index: true },
            { path: 'return-policy', element: <ReturnPolicy /> },
            { path: 'shipping-policy', element: <ShippingPolicy /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'terms-and-conditions', element: <Tac /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/view" replace />, index: true },
            { path: 'view', element: <EcommerceInvoice /> },
            { path: 'create', element: <EcommerceInvoiceGenerator /> },
          ],
        },
      ],
    },

    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralOrders = Loadable(lazy(() => import('../pages/dashboard/GeneralOrdersList')));
const GeneralOrder = Loadable(lazy(() => import('../pages/dashboard/GeneralOrder')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const ReturnPolicy = Loadable(lazy(() => import('../pages/dashboard/ReturnPolicy')));
const ShippingPolicy = Loadable(lazy(() => import('../pages/dashboard/ShippingPolicy')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/dashboard/PrivacyPolicy')));
const Tac = Loadable(lazy(() => import('../pages/dashboard/Tac')));
const EcommerceModel = Loadable(lazy(() => import('../pages/dashboard/EcommerceModel')));
const EcommerceTexture = Loadable(lazy(() => import('../pages/dashboard/EcommerceTexture')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const EcommerceInvoiceGenerator = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoiceGenerator')));
const NewPortfolio = Loadable(lazy(() => import('../pages/dashboard/NewPortfolio')));
const PortfolioList = Loadable(lazy(() => import('../pages/dashboard/PortfolioList')));
const EditPortfolio = Loadable(lazy(() => import('../pages/dashboard/EditPortfolio')));



