// hooks
import useAuth from '../hooks/useAuth';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <Avatar
      src={"/logo/logo.png"}
      alt={'Alphwood'}
      {...other}
    />
  );
}
